/* ==========================================================================
	SOCIAL ICON STYLES
========================================================================== */
.icon-social {
	transition:$g-trans;;
	width: 30px;
	height: 30px;

	// .cover__intro & {
	// 	fill:$white;
	// }

	@include hover() {
		transform: scale(1.4);
		z-index:10;
	}
}

@each $social-media, $color in $social-media {
	.icon-#{$social-media} {
		transition:$g-trans;
		fill:$color;
	}
}

.social-list {
	font-size:0;

	li {
		list-style:none;
		display:inline-block;
	}

	&--follow {
		li:not(:last-of-type) {
			@include remit(margin-right, $bsu);
		}
	}

}