/* ==========================================================================
	COVER PAGE STYLES
========================================================================== */

.cover__bgImg {
	@extend %cf;
	width:100%;
	height: 50vh;
	object-fit:cover;


	@include mq($small) {

		height: 100vh;
	}
}

.cover__intro {
	background-color:$white;

	// margin-bottom:-25vh;
	position:relative;
	z-index:10;

	@include mq($small) {
		height: 100vh;
		background-color:rgba($white, 0.9);
		position:absolute;
		top:0;
		left:0;
		right:0;
		width:100%;
		text-align:center;
		margin-top:0;
		margin-bottom:0;
	}

	@include mq($regular) {
		height:auto;
		left:5%;
		width:450px;
	}
}


.cover__content {
	text-align:center;
	color:$color-text;
	padding-top:5vh;
	padding-bottom:5vh;


	@include mq($small) {
		// @include span(10);
	}

	@include mq($regular) {
		// @include span(7 wide);
		// padding-top:10vh;
	}
}


// .squares {
// 	background-image: url('/assets/img/squares-isolated.svg');
// 	background-repeat:no-repeat;
// 	background-position:0px bottom;
// 	// background-size: 50% 50%;
// 	opacity:0.8;
// 	// width:320px;
// 	// height:200px;
// 	width: 50%;
// 	height: 80vh;
// 	display:block;
// 	position:absolute;
// 	top:0;
// 	left:0;
// 	// @include remit(left, 250);
// 	border:1px solid red;
// }

// .squares {
// 	background-image: url('/assets/img/squares-isolated.svg');
// 	background-repeat:no-repeat;
// 	background-position:right;
// 	// background-size: 50% 50%;
// 	opacity:0.8;
// 	// width:320px;
// 	// height:200px;
// 	width: 100%;
// 	height:200px;
// 	display:block;
// 	position:absolute;
// 	top:0;
// 	right:0;
// 	// @include remit(left, 250);
// 	border:1px solid red;
// }
