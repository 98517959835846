/* ==========================================================================
	 IMAGES
========================================================================== */
img {
	/* Responsive images (ensure images don't scale beyond their parents) */

	max-width: 100%;
	/* Part 1: Set a maxium relative to the parent */

	width: auto\9;
	/* IE7-8 need help adjusting responsive images */

	height: auto;
	/* Part 2: Scale the height according to the width, otherwise you get stretching */

	vertical-align: middle;
	-ms-interpolation-mode: bicubic;

	font-style:italic;
	/*So that `alt` text is visually offset if images don’t load */
}

/**
 * Non-fluid images if you specify 'width' and/or 'height' attributes.
 */
img[width],
img[height]{
	max-width:none;
}

svg { max-height: 100%; }

/* ==========================================================================
	 IMAGE PLACEMENTS
========================================================================== */
// .img-right {
// 	@include remit(margin-bottom, $bsu);
// 	@include remit(margin-left, $bsu);
// 	float:right;
// }

// .img-left {
// 	@include remit(margin-bottom, $bsu);
// 	@include remit(margin-right, $bsu);
// 	float:left;
// }

// .img-center {
// 	@include remit(margin-bottom, $bsu);
// 	display:block;
// 	margin-right:auto;
// 	margin-left:auto;
// }


// ========================================================================== //
//	 FIGURES
// ========================================================================== //

// figure {
// 	@include remit(margin-top, $bsu*2);
// 	@include remit(margin-bottom, $bsu*2);
// }

// figcaption {
// 	@include font-size(13, no, 20);
// 	color:$color-base;
// 	font-style:italic;
// 	@include remit(margin-top, 6);
// }

// ========================================================================== //
//	 LAZYLOAD
// ========================================================================== //
/* fade image in after load */
// .lazyload,
// .lazyloading {
// 	opacity: 0;
// }
// .lazyloaded {
// 	opacity: 1;
// 	transition: opacity 300ms;
// }
/* ==========================================================================
	 MISC/UNIQUE IMAGE STYLES
========================================================================== */

.logo {
	@include remit(margin-bottom, $bsu);
	max-width:350px;
}


%img-circle {
	@include remit(margin-bottom, $hsu);
	border-radius:50%;
	box-shadow: 0px 0px 0px 3px $white;
}