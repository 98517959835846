/*---------------------------------------------------
    COLOUR STYLES v1.1
  ---------------------------------------------------
    A set of common SASS colour styles by Terry Upton (Amasci Creative Limited)
	These should be unique for every project.
-----------------------------------------------------*/

/* ==========================================================================
   COLOUR STYLES
========================================================================== */

.color--base 		{color:$color-base !important;}
.color--sub 		{color:$color-sub !important;}
// .required 			{color:$color-base; font-weight:bold;}

// .color-error 		{color:$color-error !important;}
// .success-color		{color:$success-color;}