@charset "UTF-8";
/*---------------------------------------------------
	Initial setup to import all our SASS based files.

	by Terry Upton (Amasci Creative Limited)
-----------------------------------------------------*/
*, *:before, *:after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary, time {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background: transparent; }
  a:active, a:hover {
    outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 70%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    transform: scale3d(1, 1, 1); } }

@keyframes shake {
  0%, 100% {
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    transform: translateX(10px); } }

.slider__item {
  animation-name: zoom;
  animation-delay: 2s;
  animation-fill-mode: both;
  animation-duration: 20s;
  animation-iteration-count: infinite; }

@keyframes zoom {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

/* ==========================================================================
 INIAL RESETS
========================================================================== */
/* See Normalize for addition resets and set-ups */
*, *:before, *:after {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
figure,
dl, dd, ol, ul,
form, fieldset, legend,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0; }

/* ==========================================================================
	ONE WAY MARGINS
 ========================================================================== */
p, h1, h2, h3, h4, h5, h6, hgroup, dd, figure, pre, table, fieldset, legend, hr, time {
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

/* ==========================================================================
	MAIN FRAMEWORK DOCUMENT SETUP
 ========================================================================== */
html {
  font-size: 100%;
  overflow-y: scroll;
  height: 100%; }

body {
  margin: 0 auto;
  font-family: "aileronregular", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #666;
  height: 100%; }

.container {
  position: relative;
  max-width: 87.5em;
  min-width: 18.75em;
  margin: 0 auto; }

/* ==========================================================================
  HR RULES
========================================================================== */
hr {
  border: solid #d9d9d9 0px;
  border-top: solid #d9d9d9 1px;
  height: 0px;
  clear: both; }

/* ==========================================================================
   SELECTION STYLING
========================================================================== */
/* This is used to change the highlight colour when selecting text.
 * Nice bespoke touch to websites.
 * Full article here: http://davidwalsh.name/css-selection
 */
::-moz-selection {
  background: #297497;
  color: #FFF;
  text-shadow: none; }

::selection {
  background: #297497;
  color: #FFF;
  text-shadow: none; }

/* ==========================================================================
   HELPER STYLES
========================================================================== */
.container {
  padding: 0px 12px;
  padding: 0rem 0.75rem; }
  @media (min-width: 37.5em) {
    .container {
      padding: 0px 24px;
      padding: 0rem 1.5rem; } }

@media (max-width: 37.5em) {
  .feature, .no-gutter {
    margin-left: -12px;
    margin-left: -0.75rem;
    margin-right: -12px;
    margin-right: -0.75rem; } }

@media (min-width: 37.5em) and (max-width: 50em) {
  .feature, .no-gutter {
    margin-left: -24px;
    margin-left: -1.5rem;
    margin-right: -24px;
    margin-right: -1.5rem; } }

/* ==========================================================================
   CLEARFIX  STYLES
========================================================================== */
/*
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix class with Sass to avoid the `%cf` class appearing over
 * and over in your markup.
 */
.container:after, .cover__bgImg:after {
  content: "";
  display: table;
  clear: both; }

.clear {
  clear: both; }

/* ==========================================================================
	HIDE STYLES
========================================================================== */
.hidden {
  display: none; }

.alt {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .alt .focusable:active, .alt .focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

/* ==========================================================================
	HEADING STYLES
========================================================================== */
h1, h2, h3, h4, h5, h6 {
  color: #297497;
  font-family: "Georgia", sans-serif;
  text-rendering: optimizeLegibility; }

h1, .alpha {
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 1.33333;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h2, .beta {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.71429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h3, .gamma {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.14286;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h4, .delta {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.33333;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h5, .epsilon {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h6, .zeta {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

/* ==========================================================================
	PARAGRAPH AND LIST STYLES
========================================================================== */
/* List Style */
.cover__intro p {
  color: currentColor;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.cover__intro ul, .cover__intro ol {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: currentColor;
  list-style: none; }

.cover__intro li {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 0; }

.footer p {
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 2.18182;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #FFF; }

/* ==========================================================================
   FONT WEIGHTS AND SIZES
========================================================================== */
.cover__intro li {
  text-transform: uppercase; }

/* ==========================================================================
	BASE LINK STYLES
========================================================================== */
a {
  transition: all 0.4s ease;
  color: #297497;
  text-decoration: none; }
  a:hover, a:focus, a:active {
    color: #c06f9b;
    text-decoration: none; }

.footer a {
  color: #FFF;
  text-decoration: none; }
  .footer a:hover, .footer a:focus, .footer a:active {
    color: #FF5B00; }

/* ==========================================================================
	BUTTON STYLES
========================================================================== */
/*---------------------------------------------------
    COLOUR STYLES v1.1
  ---------------------------------------------------
    A set of common SASS colour styles by Terry Upton (Amasci Creative Limited)
	These should be unique for every project.
-----------------------------------------------------*/
/* ==========================================================================
   COLOUR STYLES
========================================================================== */
.color--base {
  color: #297497 !important; }

.color--sub {
  color: #c06f9b !important; }

/* ==========================================================================
	 IMAGES
========================================================================== */
img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  width: auto\9;
  /* IE7-8 need help adjusting responsive images */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  font-style: italic;
  /*So that `alt` text is visually offset if images don’t load */ }

/**
 * Non-fluid images if you specify 'width' and/or 'height' attributes.
 */
img[width],
img[height] {
  max-width: none; }

svg {
  max-height: 100%; }

/* ==========================================================================
	 IMAGE PLACEMENTS
========================================================================== */
/* fade image in after load */
/* ==========================================================================
	 MISC/UNIQUE IMAGE STYLES
========================================================================== */
.logo {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  max-width: 350px; }

/* ==========================================================================
	ICON STYLES
========================================================================== */
.icon {
  display: inline-block;
  fill: currentColor;
  position: relative; }

/* ==========================================================================
	SOCIAL ICON STYLES
========================================================================== */
.icon-social {
  transition: all 0.4s ease;
  width: 30px;
  height: 30px; }
  .icon-social:hover, .icon-social:focus, .icon-social:active {
    transform: scale(1.4);
    z-index: 10; }

.icon-twitter {
  transition: all 0.4s ease;
  fill: #00aced; }

.icon-facebook {
  transition: all 0.4s ease;
  fill: #3b5998; }

.icon-instagram {
  transition: all 0.4s ease;
  fill: #8a3ab9; }

.social-list {
  font-size: 0; }
  .social-list li {
    list-style: none;
    display: inline-block; }
  .social-list--follow li:not(:last-of-type) {
    margin-right: 24px;
    margin-right: 1.5rem; }

/* ==========================================================================
	MODULES
========================================================================== */
/* ==========================================================================
	LAYOUT MODULES
========================================================================== */
.feature {
  padding: 36px 12px;
  padding: 2.25rem 0.75rem;
  text-align: center; }

@media (min-width: 50em) {
  .content--primary {
    padding-right: gs-gutter(d, false, true); } }

/* ==========================================================================
   FOOTER STYLES
========================================================================== */
.footer {
  background-color: #297497;
  z-index: 10;
  position: relative;
  margin: auto;
  text-align: center;
  padding: 12px 0px;
  padding: 0.75rem 0rem; }
  @media (min-width: 50em) {
    .footer {
      position: absolute;
      bottom: 0;
      left: 24px;
      left: 1.5rem;
      padding: 0;
      background-color: transparent; } }

/* ==========================================================================
	COVER PAGE STYLES
========================================================================== */
.cover__bgImg {
  width: 100%;
  height: 50vh;
  object-fit: cover; }
  @media (min-width: 37.5em) {
    .cover__bgImg {
      height: 100vh; } }

.cover__intro {
  background-color: #FFF;
  position: relative;
  z-index: 10; }
  @media (min-width: 37.5em) {
    .cover__intro {
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0; } }
  @media (min-width: 50em) {
    .cover__intro {
      height: auto;
      left: 5%;
      width: 450px; } }

.cover__content {
  text-align: center;
  color: #666;
  padding-top: 5vh;
  padding-bottom: 5vh; }

/* ==========================================================================
	CONTACT STYLES
========================================================================== */
.tel {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  text-transform: none; }

.email {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 2;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  text-transform: none; }

/* ==========================================================================
  SLIDER
========================================================================== */
.slider__item {
  position: relative;
  overflow: hidden;
  z-index: 1; }

/* Slider */
.slick-dots {
  list-style: none;
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 100;
  padding: 12px;
  padding: 0.75rem; }
  @media (min-width: 50em) {
    .slick-dots {
      position: absolute;
      bottom: 24px;
      bottom: 1.5rem;
      right: 24px;
      right: 1.5rem;
      text-align: right;
      padding: 0;
      background-color: transparent; } }
  .slick-dots li {
    transition: all 0.4s ease;
    position: relative;
    display: inline-block;
    height: 24px;
    width: 24px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      transition: all 0.4s ease;
      border: 0;
      background: transparent;
      display: inline-block;
      height: 24px;
      width: 24px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      cursor: pointer;
      opacity: 0.3;
      color: #666;
      border: 1px solid currentColor; }
      @media (min-width: 50em) {
        .slick-dots li button {
          color: #FFF; } }
      .slick-dots li button:hover, .slick-dots li button:focus {
        transition: all 0.4s ease;
        outline: none;
        opacity: 1; }
    .slick-dots li.slick-active button {
      background-color: #c06f9b;
      color: #c06f9b;
      opacity: 1;
      border: 1px solid #c06f9b; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%); }
  .main-menu, .footer-menu, [class*="btn"] {
    display: none; }
  .footer {
    border-top: 1px solid #d9d9d9; } }
