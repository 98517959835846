/* ==========================================================================
 INIAL RESETS
========================================================================== */
/* See Normalize for addition resets and set-ups */

*, *:before, *:after {
  box-sizing: border-box;
 }

 h1,h2,h3,h4,h5,h6,
 p,blockquote,pre,
 figure,
 dl,dd,ol,ul,
 form,fieldset,legend,
 table,th,td,caption,
 hr {
	margin:0;
	padding:0;
 }

 /* ==========================================================================
	ONE WAY MARGINS
 ========================================================================== */

 p, h1, h2, h3, h4, h5, h6, hgroup, dd, figure, pre, table, fieldset, legend, hr, time {
 	@include remit(margin-bottom, $bsu);
 }


 /* ==========================================================================
	MAIN FRAMEWORK DOCUMENT SETUP
 ========================================================================== */

html {
	font-size: 100%;
	overflow-y: scroll;
	height:100%;
}

body
{
	margin:0 auto;
	font-family:$font-body;
	// @include font-size($base-font-size);
	-webkit-font-smoothing: antialiased;
	color:$color-text;
	height:100%;
}

.container {
	@extend %cf;
	@extend %padding-container;
	position:relative;
	max-width:em(1400);
	min-width:em(300);
	margin:0 auto;
}


/* ==========================================================================
  HR RULES
========================================================================== */

hr {
	border: solid $grey 0px;
	border-top:solid $grey 1px;
	height:0px;
	clear:both;
}

/* ==========================================================================
   SELECTION STYLING
========================================================================== */
/* This is used to change the highlight colour when selecting text.
 * Nice bespoke touch to websites.
 * Full article here: http://davidwalsh.name/css-selection
 */

::-moz-selection {
	background: $color-base;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $color-base;
	color: $white;
	text-shadow: none;
}