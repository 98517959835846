$slick-dot-color: $white;
$slick-dot-color-active: $color-sub;
$slick-dot-size: 24px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.3;

.slick-dots {
	list-style: none;
	display: block;
	width: 100%;
	text-align:center;
	position:relative;
	z-index:100;
	// background-color:$color-base;
	@include remit(padding, $hsu);

	@include mq($regular) {
		position:absolute;
		@include remit(bottom, $bsu);
		@include remit(right, $bsu);
		text-align: right;
		padding: 0;
		background-color:transparent;
	}

	li {
		transition:$g-trans;
		position: relative;
		display: inline-block;
		height: $slick-dot-size;
		width: $slick-dot-size;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;

		button {
			transition:$g-trans;
			border: 0;
			background: transparent;
			display: inline-block;
			height: $slick-dot-size;
			width: $slick-dot-size;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			cursor: pointer;
			opacity: $slick-opacity-not-active;
			color:$color-text;
			border:1px solid currentColor;

			@include mq($regular) {
				color:$slick-dot-color;
			}

			&:hover, &:focus {
				transition:$g-trans;
				outline: none;
				opacity: $slick-opacity-on-hover;
			}
		}
		&.slick-active button {
			background-color:$slick-dot-color-active;
			color: $slick-dot-color-active;
			opacity: $slick-opacity-on-hover;
			border:1px solid $slick-dot-color-active;
		}
	}
}