/* ==========================================================================
   FOOTER STYLES
========================================================================== */

.footer {
	background-color:$color-base;
	z-index:10;
	position:relative;
	margin:auto;
	text-align:center;
	@include remit(padding, $hsu 0);

	@include mq($regular) {
		position:absolute;
		bottom:0;
		@include remit(left, $bsu);
		padding:0;
		background-color:transparent;
	}
}