/* ==========================================================================
  SLIDER
========================================================================== */
.slider {

	&__item {
		position:relative;
		overflow:hidden;
		z-index:1;
	}
}


