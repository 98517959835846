/* ==========================================================================
	CONTACT STYLES
========================================================================== */

.tel {
	@include font-size(18, yes, 18*1.5);
	text-transform:none;
}

.email {
	@include font-size(18, yes, 18*2);
	text-transform:none;
}
