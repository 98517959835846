.slider__item {
	animation-name:zoom;
	animation-delay:2s;
	animation-fill-mode:both;
	animation-duration:20s;
	 animation-iteration-count:infinite;
}


@keyframes zoom {
	0% { transform: scale(1); }
	50% { transform: scale(1.2); }
	100% { transform: scale(1); }
}